import React from 'react';
import '../css/Footer.css'; // Import the CSS file for styling

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      {/* Company Information */}
      <div className="footer-left">
        <p className="footer-logo"></p>
        <p>© {new Date().getFullYear()} RootNeurons. All rights reserved.</p>
        <p>Phone: +91 9763841037 | Email: sachinsabale141@gmail.com</p>

      </div>

      {/* Quick Links */}
      <div className="footer-links">
        <h4>Quick Links</h4>
        <ul>
          <li><a>About Us</a></li>
          <li><a>Services</a></li>
          <li><a>Contact</a></li>
          <li><a>Privacy Policy</a></li>
          <li><a>Terms of Service</a></li>
        </ul>
      </div>

      {/* Government Links */}
      <div className="footer-government-links">
        <h4>Important Government Links</h4>
        <ul>
          <li><a href="https://www.maharashtra.gov.in/" target="_blank" rel="noopener noreferrer">Maharashtra Government</a></li>
          <li><a href="https://www.india.gov.in/" target="_blank" rel="noopener noreferrer">Central Government</a></li>
          <li><a href="https://www.mahapolice.gov.in/" target="_blank" rel="noopener noreferrer">Maharashtra Police</a></li>
          <li><a href="https://www.mygov.in/" target="_blank" rel="noopener noreferrer">MyGov India</a></li>
        </ul>
      </div>
    </div>

    {/* Footer Bottom */}
    <div className="footer-bottom">
      <p>Designed and developed by RootNeurons</p>
    </div>
  </footer>
);

export default Footer;
