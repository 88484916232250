import React from 'react';
import '../css/Home.css'; // Import the CSS file for styling

const Home = () => (
  <div className="contact-page">
    <div className="contact-header">
      <h2>स्वराज्य संकल्प भूमी पेमगिरी</h2>
      <p> ....लवकरच येत आहे</p>
    </div>

    <section className="marquee-section">
      <marquee behavior="scroll" direction="left" scrollamount="8">
        पेमगिरी हे आपले एक ऐतिहासिक आणि संपन्न गाव आहे, जे संगमनेर तालुक्यात आणि आहिल्यानगर जिल्ह्यात स्थित आहे. समृद्ध शेती, जलसंपत्ती आणि सांस्कृतिक वारसा यामुळे हे गाव विशेष आहे. येथे परंपरा, सण-उत्सव आणि निसर्ग सौंदर्याचा उत्तम संगम आहे. गावाच्या प्रगतीसाठी आम्ही एक Website घेऊन येत आहोत. त्याबद्दल तुमच्या कल्पना व सूचनांचे स्वागत आहे.......
      </marquee>
    </section>

    {/* Local Team Section */}
    <section className="team-section">
      <h3>आपल्या कल्पना सुचवण्यासाठी आमच्या स्थानिक प्रतिनिधींशी संपर्क साधा:</h3>
      <div className="team-members">
        <div className="team-member">
          <h4>स्वप्नील डुबे</h4>
          <p>माहिती आणि जनसंपर्क अधिकारी</p>
        </div>

        <div className="team-member">
          <h4>सचिन साबळे</h4>
          <p>संगणक प्रणाली विकासक</p>
        </div>
      </div>
    </section>

    {/* Contact Details */}
    <section className="contact-details">
      <h3>संपर्क तपशील:</h3>
      <p><strong>फोन:</strong> +91 9143451451</p>
      <p><strong>ईमेल:</strong> patilonlineservices1358@gmail.com</p>
      <p><strong>पत्ता:</strong> पेमगिरी, संगमनेर तालुका, अहमदनगर जिल्हा, महाराष्ट्र</p>
    </section>
    
  </div>
);

export default Home;
